<template>
  <div id="app" class="container">
    <div class="row center">
      <Quiz></Quiz>
    </div>
    <Comparator :recoMotors="reco"></Comparator>
  </div>
</template>

<script>
import Comparator from "./components/Comparator.vue";
import Quiz from "./components/Quiz.vue";
export default {
  name: "App",
  components: {
    Quiz,
    Comparator,
  },
  data() {
    return {
      reco: [0, 1],
    };
  },
};
</script>